import React, { useState, useEffect } from 'react'
import DynamicRenderer from '../../components/DynamicRenderer'
import SubPaletteViewer from '../../components/SubPaletteViewer'

import {
  patternColors,
  patternDesigns,
  mirrorBands,
  trayPatterns,
  songIds,
  titleColors,
  titleDesigns,
  colorPaletteNames,
  masterPalettes,
} from './constants'

export default function Arttest() {
  // These selections don't match expectations from the OpenseaAllPattern component
  const [selections, setSelections] = useState({
    mirrorBand: 'blocky',
    trayPattern: 'blocky',
    songId: '1',
    fillColor: 255,
    titleDesign: titleDesigns[0], // Default value
    patternDesign: patternDesigns[0],
    titleColor: titleColors[0], // Default value
    patternColor: patternColors[0], // Default value
    innerHubValue: 60,
    colorPalette: 'Angel',
    ringCount: 2,
    subPalette: [
      '#363F82',
      '#FDC499',
      '#014920',
      '#172E43',
      '#55001F',
      '#0F435E',
      '#55001F',
    ],
  })

  useEffect(() => {
    console.log('selections: ', selections)
  }, [selections])

  const handleSelectChange = (event) => {
    const { name, value } = event.target
    setSelections((prevSelections) => ({
      ...prevSelections,
      [name]: value,
    }))
  }

  const handleInnerHubValueChange = (event) => {
    const { name, value } = event.target
    setSelections((prevSelections) => ({
      ...prevSelections,
      [name]: parseInt(value), // Parse value as an integer
    }))
  }

  const handleRingCountChange = (event) => {
    const { name, value } = event.target
    // Ensure ringCount is within the range of 1 - 9
    const ringCount = Math.min(9, Math.max(1, parseInt(value)))
    setSelections((prevSelections) => ({
      ...prevSelections,
      [name]: ringCount,
    }))
  }

  const handleSubPaletteColor1Change = (event) => {
    const { value } = event.target
    const updatedSubPalette = [...selections.subPalette] // Create a copy of the subPalette array
    updatedSubPalette[0] = value // Update the first element with the new value

    setSelections((prevSelections) => ({
      ...prevSelections,
      subPalette: updatedSubPalette, // Update the subPalette array
    }))
  }

  const handleSubPaletteColor2Change = (event) => {
    const { value } = event.target
    const updatedSubPalette = [...selections.subPalette] // Create a copy of the subPalette array
    updatedSubPalette[1] = value // Update the first element with the new value

    setSelections((prevSelections) => ({
      ...prevSelections,
      subPalette: updatedSubPalette, // Update the subPalette array
    }))
  }

  const handleSubPaletteColor3Change = (event) => {
    const { value } = event.target
    const updatedSubPalette = [...selections.subPalette] // Create a copy of the subPalette array
    updatedSubPalette[2] = value // Update the first element with the new value

    setSelections((prevSelections) => ({
      ...prevSelections,
      subPalette: updatedSubPalette, // Update the subPalette array
    }))
  }

  const handleSubPaletteColor4Change = (event) => {
    const { value } = event.target
    const updatedSubPalette = [...selections.subPalette] // Create a copy of the subPalette array
    updatedSubPalette[3] = value // Update the first element with the new value

    setSelections((prevSelections) => ({
      ...prevSelections,
      subPalette: updatedSubPalette, // Update the subPalette array
    }))
  }

  const handleSubPaletteColor5Change = (event) => {
    const { value } = event.target
    const updatedSubPalette = [...selections.subPalette] // Create a copy of the subPalette array
    updatedSubPalette[4] = value // Update the first element with the new value

    setSelections((prevSelections) => ({
      ...prevSelections,
      subPalette: updatedSubPalette, // Update the subPalette array
    }))
  }

  const handleSubPaletteColor6Change = (event) => {
    const { value } = event.target
    const updatedSubPalette = [...selections.subPalette] // Create a copy of the subPalette array
    updatedSubPalette[5] = value // Update the first element with the new value

    setSelections((prevSelections) => ({
      ...prevSelections,
      subPalette: updatedSubPalette, // Update the subPalette array
    }))
  }

  const handleSubPaletteColor7Change = (event) => {
    const { value } = event.target
    const updatedSubPalette = [...selections.subPalette] // Create a copy of the subPalette array
    updatedSubPalette[6] = value // Update the first element with the new value

    setSelections((prevSelections) => ({
      ...prevSelections,
      subPalette: updatedSubPalette, // Update the subPalette array
    }))
  }

  const selectionsKey = JSON.stringify(selections)

  return (
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-between',
        width: '100vw',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Mirror Band:
            <select
              name="mirrorBand"
              value={selections.mirrorBand}
              onChange={handleSelectChange}
            >
              {mirrorBands.map((band) => (
                <option key={band} value={band}>
                  {band}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Tray Pattern:
            <select
              name="trayPattern"
              value={selections.trayPattern}
              onChange={handleSelectChange}
            >
              {trayPatterns.map((pattern) => (
                <option key={pattern} value={pattern}>
                  {pattern}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Color Palette:
            <select
              name="colorPalette"
              value={selections.colorPalette}
              onChange={handleSelectChange}
            >
              {colorPaletteNames.map((paletteName) => (
                <option key={paletteName} value={paletteName}>
                  {paletteName}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Inner Hub Value:
            <input
              type="range"
              name="innerHubValue"
              min="15"
              max="1000"
              value={selections.innerHubValue}
              onChange={handleInnerHubValueChange}
            />
            <span>{selections.innerHubValue}</span>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Ring Count:
            <input
              type="range"
              name="ringCount"
              min="1"
              max="9"
              value={selections.ringCount}
              onChange={handleRingCountChange}
            />
            <span>{selections.ringCount}</span>
          </label>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Song ID:
            <select
              name="songId"
              value={selections.songId}
              onChange={handleSelectChange}
            >
              {songIds.map((id) => (
                <option key={id} value={id}>
                  {id}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Title Design:
            <select
              name="titleDesign"
              value={selections.titleDesign}
              onChange={handleSelectChange}
            >
              {titleDesigns.map((design) => (
                <option key={design} value={design}>
                  {design}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Pattern Design:
            <select
              name="patternDesign"
              value={selections.patternDesign}
              onChange={handleSelectChange}
            >
              {patternDesigns.map((design) => (
                <option key={design} value={design}>
                  {design}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Title Color:
            <select
              name="titleColor"
              value={selections.titleColor}
              onChange={handleSelectChange}
            >
              {titleColors.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            Pattern Color:
            <select
              name="patternColor"
              value={selections.patternColor}
              onChange={handleSelectChange}
            >
              {patternColors.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            SubPalette Color 1:
            <select
              name="subPaletteColor1"
              value={selections.subPalette[0]}
              onChange={handleSubPaletteColor1Change}
              style={{ color: selections.subPalette[0] }}
            >
              {masterPalettes[selections.colorPalette].map((color) => (
                <option key={color} value={color} style={{ color: color }}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            SubPalette Color 2:
            <select
              name="subPaletteColor1"
              value={selections.subPalette[1]}
              onChange={handleSubPaletteColor2Change}
              style={{ color: selections.subPalette[1] }}
            >
              {masterPalettes[selections.colorPalette].map((color) => (
                <option key={color} value={color} style={{ color: color }}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            SubPalette Color 3:
            <select
              name="subPaletteColor1"
              value={selections.subPalette[2]}
              onChange={handleSubPaletteColor3Change}
              style={{ color: selections.subPalette[2] }}
            >
              {masterPalettes[selections.colorPalette].map((color) => (
                <option key={color} value={color} style={{ color: color }}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            SubPalette Color 4:
            <select
              name="subPaletteColor1"
              value={selections.subPalette[3]}
              onChange={handleSubPaletteColor4Change}
              style={{ color: selections.subPalette[3] }}
            >
              {masterPalettes[selections.colorPalette].map((color) => (
                <option key={color} value={color} style={{ color: color }}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            SubPalette Color 5:
            <select
              name="subPaletteColor1"
              value={selections.subPalette[4]}
              onChange={handleSubPaletteColor5Change}
              style={{ color: selections.subPalette[4] }}
            >
              {masterPalettes[selections.colorPalette].map((color) => (
                <option key={color} value={color} style={{ color: color }}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            SubPalette Color 6:
            <select
              name="subPaletteColor1"
              value={selections.subPalette[5]}
              onChange={handleSubPaletteColor6Change}
              style={{ color: selections.subPalette[5] }}
            >
              {masterPalettes[selections.colorPalette].map((color) => (
                <option key={color} value={color} style={{ color: color }}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ backgroundColor: 'white' }}>
            SubPalette Color 7:
            <select
              name="subPaletteColor1"
              value={selections.subPalette[6]}
              onChange={handleSubPaletteColor7Change}
              style={{ color: selections.subPalette[6] }}
            >
              {masterPalettes[selections.colorPalette].map((color) => (
                <option key={color} value={color} style={{ color: color }}>
                  {color}
                </option>
              ))}
            </select>
          </label>
        </div>
        <SubPaletteViewer subPalette={selections.subPalette} />
      </div>
      <div>
        <DynamicRenderer selections={selections} key={selectionsKey} />
      </div>
    </div>
  )
}
