import React from 'react'
import NoBackgroundTest from '../components/NoBackgroundTest'
import TitlePattern from '../components/TitlePattern'

const selections = {
  mirrorBand: 'blank1',
  trayPattern: 'blocky',
  songId: '1',
  fillColor: 255,
  // titlePatternDesign: "a", // Default value
  titleColor: 'blueberry', // Default value
  patternColor: 'blue', // Default value
  innerHubValue: 60,
  colorPalette: 'B',
  ringCount: 2,
}

export default function Blank2() {
  return (
    <div>
      <TitlePattern selections={selections} />
      <NoBackgroundTest selections={selections} />
    </div>
  )
}
