import { ReactComponentElement, useState, useEffect } from 'react'

export default function TitlePattern(props) {
  // const [letter, setLetter] = useState();
  // const [titleColor, setTitleColor] = useState();
  // const [patternColor, setPatternColor] = useState();

  const selections = props.selections

  const { songId, titleDesign, patternDesign, titleColor, patternColor } =
    selections

  const patternDesignUpdate = {
    Clover: 1,
    'Free Flow': 2,
    'Grade Separation': 3,
    Heptahedron: 4,
    Cube: 5,
    Geoforms: 6,
    'Sprig & Molecule': 7,
    Emitter: 8,
    Biomarker: 9,
    Bookend: 10,
    Mirrored: 11,
    Directional: 12,
    'Planatary Bodies': 13,
    Vortices: 14,
    Plotted: 15,
  }

  const patternColorUpdate = {
    Gold: 'gold',
    Green: 'green',
    Ice: 'ice',
    Lilac: 'lilac',
    Moss: 'moss',
    Redclay: 'redclay',
    Rose: 'rose',
    Screen: 'screen',
    Soil: 'soil',
  }

  const titleDesignUpdate = {
    I: 'a',
    II: 'b',
    III: 'c',
  }

  const titleColorUpdate = {
    Chamomille: 'chamomille',
    Goldenrod: 'goldenrod',
    Hydrant: 'hydrant',
    Mentha: 'mint',
    Orange: 'orange',
    Paleblue: 'paleblue',
    Pink: 'pink',
    Purple: 'purple',
    Salmon: 'salmon',
    Turquoise: 'turquoise',
    Brown: 'brown',
    Magenta: 'magenta',
  }

  if (titleDesign && patternDesign && titleColor && patternColor) {
    console.log(
      `pattern path: /svg-icons/p_${patternDesignUpdate[patternDesign]}_${patternColorUpdate[patternColor]}.svg`
    )
    console.log(
      `title path: /svg-icons/t_${songId}${titleDesignUpdate[titleDesign]}_${titleColorUpdate[titleColor]}.svg`
    )
    return (
      <div className='title-scaling' style={{ position: 'absolute', top: 0, left: 0 }}>
        <div className="spin-container" style={{ position: 'relative' }} >
          <img
            src={`/svg-icons/t_${songId}${titleDesignUpdate[titleDesign]}_${titleColorUpdate[titleColor]}.svg`}
            alt="Title SVG"
            className="spin-element"
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, maxWidth:'80vw' }}
            
          />
          <img
            src={`/svg-icons/p_${patternDesignUpdate[patternDesign]}_${patternColorUpdate[patternColor]}.svg`}
            alt="Pattern SVG"
            className="spin-element"
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, maxWidth:'80vw' }}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className='title-scaling' style={{ position: 'absolute', top: 0, left: 0 }}>
        <div className="spin-container" style={{ position: 'relative' }}>
          <img
            src={`/svg-icons/C4_Blank_Disc.svg`}
            alt="Pattern SVG"
            className="spin-element"
            style={{ position: 'absolute', top: 0, left: 0, maxWidth:'80vw'  }}
          />
        </div>
      </div>
    )
  }
}

//next step is to make the patterns match the CD size
