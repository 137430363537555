import { React, useEffect, useState, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import DynamicRenderer from '../components/DynamicRenderer'

export default function Renderer() {
  const [fetchedMetadata, setFetchedMetadata] = useState()
  const [loading, setLoading] = useState(false)
  // const [selections, setSelections] = useState({}) //defaults  mirrorBand: "blocky",
  //   trayPattern: "blocky",
  //   songId: "1",
  //   fillColor: 255,
  //   titlePatternDesign: "a",
  //   titleColor: "blueberry",
  //   patternColor: "blue",
  //   innerHubValue: 60,
  //   colorPalette: "B",
  //   ringCount: 2,
  //   seedNumber: 200,
  const { tokenId } = useParams()

  const audioRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  function toggleAudio() {
    console.log('audio toggled from canvas click')
    if (isPlaying) {
      handlePause()
    } else {
      handlePlay()
    }
  }

  const handlePlay = () => {
    console.log('handlePlay called')
    setIsPlaying(true)
    audioRef.current.play()
  }

  const handlePause = () => {
    console.log('handlePause called')
    setIsPlaying(false)
    audioRef.current.pause()
  }
//*********************************************** */
//If we have issues with metadata, on the renderer, this is the spot to check out!
useEffect(() => {
  if (loading) return;

  const asyncFetch = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `https://c4testbucket.s3.us-east-2.amazonaws.com/metadata/${tokenId}.json`
      );

      if (!res.ok) {
        // Fallback to the commented route if there's an error
        console.log('Falling back to the metadata api route...');
        const fallbackRes = await fetch(
          `https://c4-generator.vercel.app/api/cdMetadata/${tokenId}`
        );

        if (!fallbackRes.ok) {
          throw new Error(fallbackRes?.error ?? 'Fallback: Bad response');
        }

        const fallbackData = await fallbackRes.json();
        setFetchedMetadata(fallbackData);
      } else {
        const data = await res.json();
        setFetchedMetadata(data);
      }
    } catch (err) {
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  asyncFetch();
}, [tokenId]);

  //********************************************** */

  useEffect(() => {
    console.log('fetched metadata object: ', fetchedMetadata)
  }, [fetchedMetadata])

  // Could just useMemo this rather than needing to have a state manager as it is always dependent on fetchedMetadata and isn't set anywhere else
  const selections = useMemo(() => {
    if (!(fetchedMetadata && fetchedMetadata.attributes)) return {}

    function camelCase(str) {
      // Using replace method with regEx
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, '')
    }

    // Transform keys to camelcase
    let keyedTraits = fetchedMetadata?.attributes.reduce(
      (res, { trait_type, value }) => ({
        ...res,
        [camelCase(trait_type)]: value,
      }),
      {}
    )

    const { songId, mirrorBandSubPalette, trayPatternSubPalette } =
      fetchedMetadata ?? {}

    //     fetchedMetadata?.attributes?.forEach((attribute) => {
    //   switch (attribute.trait_type) {
    //     case 'Mirror Band':
    //       updatedSelections.mirrorBand = attribute.value
    //       break
    //     case 'Tray Liner':
    //       updatedSelections.trayPattern = attribute.value
    //       break
    //     case 'Song':
    //       updatedSelections.songId = fetchedMetadata.songId
    //       break
    //     case 'Mirror Band Color Way':
    //       updatedSelections.mirrorBandSubPalette =
    //         fetchedMetadata.mirrorBandSubPalette
    //       updatedSelections.trayPatternSubPalette =
    //         fetchedMetadata.trayPatternSubPalette
    //       break
    //     case 'Inner Hub':
    //       updatedSelections.innerHubValue = attribute.value
    //       updatedSelections.ringCount = fetchedMetadata.ringCount
    //       break
    //     case 'Title Imprint':
    //       updatedSelections.titleDesign = attribute.value
    //       break
    //     case 'Overlay':
    //       updatedSelections.patternDesign = attribute.value
    //       break
    //     case 'Imprint Color':
    //       updatedSelections.titleColor = attribute.value
    //       break
    //     case 'Overlay Color':
    //       updatedSelections.patternColor = attribute.value
    //       break
    //     case 'Seed Number':
    //       updatedSelections.seedNumber = attribute.value
    //       break
    //     case 'Memory' :
    //       updatedSelections.memory = attribute.value
    //     default:
    //       // Handle other attributes as needed
    //       break
    //   }
    // })

    console.log('keyedTraits::', keyedTraits)

    return {
      // As long as we don't want to change the names all future props will now flow through
      ...keyedTraits,
      trayPattern: keyedTraits.trayLiner,
      innerHub: keyedTraits.innerHub,
      titleDesign: keyedTraits.titleImprint,
      patternDesign: keyedTraits.overlay,
      titleColor: keyedTraits.titleColor,
      patternColor: keyedTraits.overlayColor,
      ringCount: fetchedMetadata.ringCount,
      songId,
      mirrorBandSubPalette,
      trayPatternSubPalette,
    }
  }, [fetchedMetadata])

  return (
    <div onClick={selections.memory == 'Blank' ? undefined : toggleAudio}>
      <audio
        ref={audioRef}
        src={`/songs/${selections.songId}.wav`}
        onEnded={handlePause}
        controls
        hidden
      />
      <DynamicRenderer selections={selections} />
    </div>
  )
}
