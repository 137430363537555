import React from 'react'

export default function SubPaletteViewer({subPalette}) {
  return (
  <div>
      {subPalette.map((color, index) => (
        <div
          key={index}
          style={{
            backgroundColor: color,
            width: "20px",
            height: "20px",
            border: "1px solid #000",
            marginRight: "5px",
          }}
        />
      ))}
    </div>
  )
}
