const mirrorBands = ['noise', 'stripes', 'super', 'blocky', 'bezier']
const trayPatterns = ['mark', 'spin', 'noise', 'static', 'blocky']
const songIds = ['1', '2', '3', '4', '5']
const titleDesigns = ['a', 'b', 'c']
const patternDesigns = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
]
// const fillColors = [10, 255]
const titleColors = [
  'chamomille',
  'goldenrod',
  'hydrant',
  'mint',
  'orange',
  'paleblue',
  'pink',
  'purple',
  'salmon',
  'turqouise',
  'brown',
]
const patternColors = [
  'gold',
  'green',
  'ice',
  'lilac',
  'moss',
  'redclay',
  'rose',
  'screen',
  'soil',
]
const colorPaletteNames = ['Super', 'Angel', '90s']

const masterPalettes = {
  Super: [
    '#B69674',
    '#61553F',
    '#B5756A',
    '#4C4C42',
    '#4F608C',
    '#202311',
    '#7574AA',
    '#C5C6C0',
    '#F7AE04',
    '#7F5127',
    '#582E29',
    '#694234',
    '#EBC908',
    '#4C4679',
    '#D96249',
    '#10130E',
    '#249E92',
    '#9E5847',
    '#4C5543',
    '#ACDBD1',
    '#545A19',
    '#ABA4A5',
    '#A97AAA',
    '#787969',
    '#C8CA05',
    '#D9B883',
    '#D59688',
    '#70756A',
    '#A1708C',
    '#F2C729',
    '#80D15F',
    '#5D889C',
    '#B6B728',
    '#E1CB84',
    '#CFD418',
    '#F4613A',
    '#E54864',
    '#7078B5',
    '#4866AC',
    '#75617B',
  ],
  '90s': [
    '#B69674',
    '#61553F',
    '#B5756A',
    '#4C4C42',
    '#4F608C',
    '#202311',
    '#7574AA',
    '#C5C6C0',
    '#F7AE04',
    '#7F5127',
    '#582E29',
    '#694234',
    '#EBC908',
    '#4C4679',
    '#D96249',
    '#10130E',
    '#249E92',
    '#9E5847',
    '#4C5543',
    '#ACDBD1',
    '#545A19',
    '#ABA4A5',
    '#A97AAA',
    '#787969',
    '#C8CA05',
    '#D9B883',
    '#D59688',
    '#70756A',
    '#A1708C',
    '#F2C729',
    '#80D15F',
    '#5D889C',
    '#B6B728',
    '#E1CB84',
    '#CFD418',
    '#F4613A',
    '#E54864',
    '#7078B5',
    '#4866AC',
    '#75617B',
  ],
  Angel: [
    '#5FA4D5',
    '#2E4B45',
    '#1453A8',
    '#D5BBC4',
    '#A5C3D7',
    '#392EA1',
    '#014920',
    '#584D92',
    '#1E885E',
    '#B3F5D1',
    '#AEDCF2',
    '#0F435E',
    '#9E674B',
    '#A66431',
    '#8BF9A0',
    '#FDC499',
    '#5A5FB6',
    '#55001F',
    '#1E885E',
    '#363F82',
    '#6D7494',
    '#60ABB9',
    '#9F4945',
    '#8C246A',
    '#172E43',
    '#FDC499',
    '#437BDF',
    '#55001F',
    '#1E885E',
    '#134F3F',
  ],
}

export {
  patternColors,
  patternDesigns,
  mirrorBands,
  trayPatterns,
  songIds,
  titleColors,
  titleDesigns,
  colorPaletteNames,
  masterPalettes,
}