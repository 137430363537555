import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DynamicRendererNoBg from "../components/DynamicRendererNoBg";

export default function RendererNoBg() {
  const [fetchedMetadata, setFetchedMetadata] = useState();
  const [selections, setSelections] = useState({}); //defaults  mirrorBand: "blocky",
  //   trayPattern: "blocky",
  //   songId: "1",
  //   fillColor: 255,
  //   titlePatternDesign: "a",
  //   titleColor: "blueberry",
  //   patternColor: "blue",
  //   innerHubValue: 60,
  //   colorPalette: "B",
  //   ringCount: 2,
  //   seedNumber: 200,
  const { tokenId } = useParams();

  useEffect(() => {
    fetch(`https://c4-generator.vercel.app/api/cdMetadata/${tokenId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFetchedMetadata(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [tokenId]);

  useEffect(() => {
    console.log("fetched metadata object: ", fetchedMetadata);
  }, [fetchedMetadata]);

  //the following useEffect takes the fetched metadata, and reformats it into the object that is needed by the dynamic renderer
  useEffect(() => {
    // Create a copy of the selections object
    const updatedSelections = { ...selections }

    fetchedMetadata?.attributes?.forEach((attribute) => {
      switch (attribute.trait_type) {
        case 'Mirror Band':
          updatedSelections.mirrorBand = attribute.value
          break
        case 'Tray Liner':
          updatedSelections.trayPattern = attribute.value
          break
        case 'Audio':
          updatedSelections.songId = fetchedMetadata.songId
          break
        case 'Mirror Band Colorway':
          updatedSelections.mirrorBandSubPalette =
            fetchedMetadata.mirrorBandSubPalette
          updatedSelections.trayPatternSubPalette =
            fetchedMetadata.trayPatternSubPalette
          break
        case 'Inner Hub':
          updatedSelections.innerHubValue = attribute.value
          updatedSelections.ringCount = fetchedMetadata.ringCount
          break
        case 'Title Imprint':
          updatedSelections.titleDesign = attribute.value
          break
        case 'Overlay':
          updatedSelections.patternDesign = attribute.value
          break
        case 'Title Color':
          updatedSelections.titleColor = attribute.value
          break
        case 'Overlay Color':
          updatedSelections.patternColor = attribute.value
          break
        case 'Seed Number':
          updatedSelections.seedNumber = attribute.value
          break
        case 'Memory': 
        updatedSelections.memory = attribute.value
        default:
          // Handle other attributes as needed
          break
      }
    })

    // Update the selections with the modified object
    setSelections(updatedSelections)
  }, [fetchedMetadata])

  useEffect(() => {
    console.log("formatted selections: ", selections);
  }, [selections]);

  return (
    <div>
      <DynamicRendererNoBg selections={selections} />
    </div>
  );
}

//okay, now I need to determine the selected colors in the master component, but for that I should also pass the seed number value in the selections object
