
import AllPatternsNoBg from "./AllPatternsNoBg";
import NoBackgroundTest from "./NoBackgroundTest";
import OpenseaAllPatternsTest from "./OpenseaAllPatternsTest";
import TitlePattern from "./TitlePattern";
import TitlePatternNoSpin from "./TitlePatternNoSpin";

const mirrorBands = ["noise", "stripes", "super", "blocky", "bezier"]; // holo and bezier removed temporarily
const trayPatterns = ["mark", "spin", "noise", "static", "blocky"];

const blankSelections = {
  mirrorBand: "blocky",
  trayPattern: "blocky",
  songId: "1",
  fillColor: 255,
  // titlePatternDesign: "a", // Default value
  titleColor: "blueberry", // Default value
  patternColor: "blue", // Default value
  innerHubValue: 60,
  colorPalette: "90s",
  ringCount: 2,
};

function DynamicRendererNoBg({ selections }) {
  //   const randomMirrorBandIndex = Math.floor(Math.random() * mirrorBands.length);
  //   const randomTrayPatternIndex = Math.floor(Math.random() * trayPatterns.length);

  //   const selections = {
  //     mirrorBand: mirrorBands[randomMirrorBandIndex],
  //     trayPattern: trayPatterns[randomTrayPatternIndex],
  //   };

  //   const song = Math.floor(Math.random() * (5 - 1) + 1)

  console.log("selections is: ", selections);

  if (selections.songId != undefined) {
    console.log("first return fired");

    return (
      <div className="DynamicRenderer">
        <TitlePatternNoSpin selections={selections} />
        <AllPatternsNoBg selections={selections} />
      </div>
    );
  }
  console.log("second return fired");
  if (selections.memory === 'Blank'){
  return (
    <div className="DynamicRenderer">
      <NoBackgroundTest selections={blankSelections} />
      <TitlePatternNoSpin selections={blankSelections}/>
    </div>
  );}
  else {
    return
  }
}

//NEXT STEP IS FIGURING OUT A FIX FOR CONSISTEN TITLEPATTERN SPINNING

export default DynamicRendererNoBg;
