import React, { useEffect, useRef, useState } from "react";

const NoBackgroundTest = ({ selections }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [formattedTime, setFormattedTime] = useState();
  const [currentTime, setCurrentTime] = useState(0);

  //console log the above properties
  useEffect(() => {
    console.log("selections changed in the openseapatterns component");
  }, [selections]);

  const renderRef = useRef();
  let p5Instance;

  let angle = 0;
  let rotationSpeed = 1.0; // Adjust speed
  let tick = 0.1;
  let xOff = 0; // Noise function
  let chosenColors; // color index for trayMark function
  let curvePoints = [];

  //colors for trayMark function
  const choose = (arr) => arr[Math.floor(Math.random(arr.length))];

  //const colorsA = ["#B69674", "#61553F", "#B5756A", "#4C4C42", "#4F608C"]; //too muddy

  const colorsB = ["#202311", "#7574AA", "#C5C6C0", "#F7AE04", "#7F5127"];

  const colorsC = ["#582E29", "#694234", "#EBC908", "#4C4679", "#D96249"];

  const colorsD = [
    "#10130E",
    "#249E92",
    "#9E5847",
    "#4C5543",
    "#ACDBD1",
    "#7078B5",
  ];

  const colorsE = [
    "#545A19",
    "#249E92",
    "#ABA4A5",
    "#A97AAA",
    "#787969",
    "#C8CA05",
    "#4C4679",
  ];

  const colorsF = [
    "#D9B883",
    "#D59688",
    "#EBC908",
    "#70756A",
    "#A1708C",
    "#F2C729",
  ];

  const colorsG = [
    "#80D15F",
    "#EBC908",
    "#5D889C",
    "#B6B728",
    "#E1CB84",
    "#CFD418",
  ];

  const colorsH = ["#F4613A", "#E54864", "#7078B5", "#4866AC", "#75617B"];

  const colorsI = [
    "#582E29",
    "#694234",
    "#202311",
    "#7574AA",
    "#C5C6C0",
    "#F7AE04",
    "#7F5127",
  ];

  const colorsJ = [
    "#EBC908",
    "#4C4679",
    "#D59688",
    "#70756A",
    "#A1708C",
    "#D96249",
  ];

  const colorsK = [
    "#10130E",
    "#249E92",
    "#D9B883",
    "#D59688",
    "#9E5847",
    "#F2C729",
    "#ACDBD1",
  ];

  const colorsL = ["#E54864", "#7078B5", "#A97AAA", "#787969", "#C8CA05"];

  const colorsM = [
    "#D9B883",
    "#D59688",
    "#70756A",
    "#80D15F",
    "#5D889C",
    "#B6B728",
    "#E54864",
  ];

  const colorsN = [
    "#80D15F",
    "#EBC908",
    "#582E29",
    "#694234",
    "#E1CB84",
    "#CFD418",
  ];

  const colorsO = ["#F4613A", "#E54864", "#7078B5", "#4866AC", "#75617B"];

  const colorsP = ["#F4613A", "#E54864", "#EBC908", "#4C4679", "#D59688"];

  const colorsSuper = [
    "#B69674",
    "#61553F",
    "#B5756A",
    "#4C4C42",
    "#4F608C",
    "#202311",
    "#7574AA",
    "#C5C6C0",
    "#F7AE04",
    "#7F5127",
    "#582E29",
    "#694234",
    "#EBC908",
    "#4C4679",
    "#D96249",
    "#10130E",
    "#249E92",
    "#9E5847",
    "#4C5543",
    "#ACDBD1",
    "#545A19",
    "#ABA4A5",
    "#A97AAA",
    "#787969",
    "#C8CA05",
    "#D9B883",
    "#D59688",
    "#70756A",
    "#A1708C",
    "#F2C729",
    "#80D15F",
    "#5D889C",
    "#B6B728",
    "#E1CB84",
    "#CFD418",
    "#F4613A",
    "#E54864",
    "#7078B5",
    "#4866AC",
    "#75617B",
  ];

  //colors for mirrorband & rest
  const colors90s = [
    "#B69674",
    "#61553F",
    "#B5756A",
    "#4C4C42",
    "#4F608C",
    "#202311",
    "#7574AA",
    "#C5C6C0",
    "#F7AE04",
    "#7F5127",
    "#582E29",
    "#694234",
    "#EBC908",
    "#4C4679",
    "#D96249",
    "#10130E",
    "#249E92",
    "#9E5847",
    "#4C5543",
    "#ACDBD1",
    "#545A19",
    "#ABA4A5",
    "#A97AAA",
    "#787969",
    "#C8CA05",
    "#D9B883",
    "#D59688",
    "#70756A",
    "#A1708C",
    "#F2C729",
    "#80D15F",
    "#5D889C",
    "#B6B728",
    "#E1CB84",
    "#CFD418",
    "#F4613A",
    "#E54864",
    "#7078B5",
    "#4866AC",
    "#75617B",
  ];

  const colorsAngel = [
    "#5FA4D5",
    "#2E4B45",
    "#1453A8",
    "#D5BBC4",
    "#A5C3D7",
    "#392EA1",
    "#014920",
    "#584D92",
    "#1E885E",
    "#B3F5D1",
    "#AEDCF2",
    "#0F435E",
    "#9E674B",
    "#A66431",
    "#8BF9A0",
    "#FDC499",
    "#5A5FB6",
    "#55001F",
    "#1E885E",
    "#363F82",
    "#6D7494",
    "#60ABB9",
    "#9F4945",
    "#8C246A",
    "#172E43",
    "#FDC499",
    "#437BDF",
    "#55001F",
    "#1E885E",
    "#134F3F",
  ];

  const colorArrays = {
    B: colorsB,
    C: colorsC,
    D: colorsD,
    E: colorsE,
    F: colorsF,
    G: colorsG,
    H: colorsH,
    I: colorsI,
    J: colorsJ,
    K: colorsK,
    L: colorsL,
    M: colorsM,
    N: colorsN,
    O: colorsO,
    P: colorsP,
    Super: colorsSuper,
    Angel: colorsAngel,
    "90s": colors90s,
  };

  const selectedArray = colorArrays[selections.colorPalette];

  const selectedColors = [
    "#f6f6f6",
    "#E5E4E2",
    "#a6a6a6",
    "#e4e4e4",
    "#f9f9f9",
  ];

  let currentColorIndex = 0;

  useEffect(() => {
    const p5 = require("p5");

    p5Instance = new p5((p) => {
      //SET UP FUNCTION

      p.setup = () => {
        p.createCanvas(
          p.min(p.windowHeight, p.windowWidth),
          p.min(p.windowHeight, p.windowWidth),
          p.WEBGL
        );
        p.colorMode(p.HSB);
        p.background(selections.fillColor);
        p.noStroke();
        p.smooth();
        p.blendMode(p.BLEND);

        // Choose a single array for trayMark (e.g., colorsA)
        // chosenColors = chooseFromFullArray([
        //   //colorsA,
        //   colorsB,
        //   colorsC,
        //   colorsD,
        //   colorsE,
        //   colorsF,
        //   colorsG,
        //   colorsH,
        //   colorsI,
        //   colorsJ,
        //   colorsK,
        //   colorsL,
        //   colorsM,
        //   colorsN,
        //   colorsO,
        //   colorsP,
        //   colors90s,
        //   colorsAngel,
        //   colorsSuper,
        // ]);

        chosenColors = selectedArray; //instead of randomly chosing an array, now chosenColors is determined by selectedArray, which is determined by props.selections.colorPalette

        //RUSS, this little for loop is what chooses the selectedColors from a given pallete.  Ideally, by making colors90s a variable that is a selectable pallete, we should have deterministic colors
        // for (let i = 0; i < 4; i++) {
        //   selectedColors.push(
        //     selectedArray[Math.floor(p.random(selectedArray.length))]
        //   );
        //   console.log(
        //     `selected colors from palette ${selections.colorPalette} are: `,
        //     selectedColors
        //   );
        // }
      };

      //toggleAudio

      // let isPlaying = false;

      // function toggleAudio() {
      //   console.log("audio toggled from canvas click");
      //   if (isPlaying) {
      //     handlePause();
      //     isPlaying = false;
      //   } else {
      //     handlePlay();
      //     isPlaying = true;
      //   }
      // }
      // p.mousePressed = toggleAudio;

      //Color Array Selection Functions

      function chooseFromFullArray(arrays) {
        return arrays[Math.floor(Math.random() * arrays.length)];
      }

      function chooseFromSelectArray(arrays) {
        return arrays[Math.floor(Math.random() * arrays.length)];
      }

      //windowResized

      p.windowResized = () => {
        // Recalculate canvas size based on the smaller dimension
        const canvasSize = p.min(p.windowWidth, p.windowHeight);
        p.resizeCanvas(canvasSize, canvasSize);
        p.background(selections.fillColor);
      };

      ////////////////////////////
      /////////////////////////
      ///////////////////////////////
      //DRAW FUNCTION//
      ////////////////////////////
      ////////////////////////////
      //////////////////////////////
      //////////////////////////////

      p.draw = () => {
        angle += rotationSpeed;
        if (angle >= 360) {
          angle = 0;
        }

        p.rotateY(p.radians(0.01)); // Play with value to get patterns
        p.rotateX(p.radians(0.01)); // Play with value to get patterns
        p.rotateZ(p.radians(angle));

        //mirror bands
        // drawStripesBand(selectedColors); //Matches Reel's Rendering 🟡 - it's pretty close, but spin speed changes on different sizes
        // drawSuperBand(selectedColors);
        // drawHolo()    //Matches Reel's Rendering✅
        // drawBlockyBand(selectedColors)  //Matches Reels Rendering ✅
        // drawNoiseBand(selectedColors); //Matches Reels Rendering ✅

        //bezier and changing curves are a combo mirrorband // _
        // drawBezierBand();  //Matches Reels Rendering ✅        |
        // drawChangingCurves(); //                                |
        ////                                                   _|

        //--------------------//

        //MIRROR BANDS SWITCH LOGIC

        // switch (selections.mirrorBand) {
        //   case "bezier":
        //     // Call the drawBezierBand function
        //     drawBezierBand();
        //     drawChangingCurves();

        //     break;
        //   case "noise":
        //     // Call the drawNoiseBand function
        //     drawNoiseBand();
        //     break;
        //   case "stripes":
        //     // Call the drawStripesBand function
        //     drawStripesBand();
        //     break;
        //   case "super":
        //     // Call the drawSuperBand function
        //     drawSuperBand();
        //     break;
        //   case "blocky":
        //     // Call the drawBlockyBand function
        //     drawBlockyBand();
        //     break;
        //   case "holo":
        //     // Call the drawHolo function
        //     drawHolo();
        //     break;
        //   default:
        //     // Handle the default case if needed
        //     break;
        // }

        //TRAY PATTERNS DRAW

        //NOTES: it turns out that sometimes when you pass a seed, it determines the specific 'pick 4' and doesn't change, so the patter stays the same each rotation

        // trayPatternMark(); // Matches Reel's Rendering 🟡 getting close, on mobile it renders in 4 distinct sections
        // trayPatternSpin(); // Matches Reel's Rendering ✅
        // trayNoiseSweep() // Matches Reel's Rendering ✅
        // trayStaticLove (); // Matches Reel's Rendering ✅
        // trayBlockyGlitchThick() // Matches Reels' Rendering 🟡 it's close, same issue where the chosen color indices don't properly repopulate

        // drawInnerHub(selectedColors);
        // drawRings();
        // drawCenterDot();

        // switch (selections.trayPattern) {
        //   case "mark":
        //     // Call the trayPatternMark function
        //     trayPatternMark();
        //     break;
        //   case "spin":
        //     // Call the trayPatternSpin function
        //     trayPatternSpin();
        //     break;
        //   case "noise":
        //     // Call the trayNoiseSweep function
        //     trayNoiseSweep();
        //     break;
        //   case "static":
        //     // Call the trayStaticLove function
        //     trayStaticLove();
        //     break;
        //   case "blocky":
        //     // Call the trayBlockyGlitchThick function
        //     trayBlockyGlitchThick();
        //     break;
        //   default:
        //     // Handle the default case if needed
        //     break;
        // }

        drawBlankBand1();

        drawInnerHub(); // alternate patterns come from changing the component.  we could make pass an argument for it, which is used for this variable, and use switch case like the others

        drawCenterDot();

        drawRings();
      };

      ////////////////////////////////////////////////////////

      ///MIRORR BANDS////////////////////////

      ////////////////////////////////////////////////////

      function drawBlankBand1() {
        let currentColorIndex = 0;
        const paletteHolo = [
          "#f6f6f6",
          "#E5E4E2",
          "#a6a6a6",
          "#e4e4e4",
          "#f9f9f9",
        ];
        p.randomSeed("seed"); // set the random seed
        p.fill(230);
        p.stroke(230);
        p.strokeWeight(0.06);

        let numPoints = 70;
        let innerRadius =  p.width * ".4";;

        p.beginShape(p.TRIANGLE_FAN);
        p.vertex(0, 0, 0);

        for (let i = 0; i <= numPoints; i++) {
          let theta = p.map(i, 0, numPoints, 50, p.TWO_PI);
          let x = p.cos(theta) * innerRadius;
          let y = p.sin(theta) * innerRadius;
          let fillColor = p.color(
            paletteHolo[currentColorIndex % paletteHolo.length]
          );
          fillColor.setAlpha(0.1);
          p.fill(fillColor, 20, 40);

          p.vertex(x, y, 0); // Play with value to get patterns

          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
        }
        p.endShape(p.CLOSE);
      }

      function drawBlankBand2() {
        let currentColorIndex = 0;
     const paletteHolo = [ 
       "#f6f6f6",
       "#E5E4E2",
       "#a6a6a6",
       "#e4e4e4",
       "#f9f9f9",
       
     ];
       
       
       p.strokeWeight(0.01);
     
       let numPoints = 70;
       let innerRadius = 0;
       let outerRadius = p.width * ".4";
       
       let dyna = p.cos(p.sin(p.frameCount * (.1)));
     
       p.beginShape();
       for (let i = 0; i <= numPoints; i++) {
        
         let x = p.cos(200) * outerRadius;
         let y = p.sin(200) * outerRadius;
         let hue = p.map(i, 0, outerRadius * p.sin(.1), 0, 360);
         let fillColor = p.color(paletteHolo[currentColorIndex]);
         fillColor.setAlpha(.6 * dyna);
         p.fill(fillColor, 20, 40);
         p.vertex(x, y, 0.1);
         
         currentColorIndex = (currentColorIndex + 1) % paletteHolo.length;
       }
       p.endShape(p.CLOSE);
     }


      function drawStripesBand(colors) {
        p.randomSeed("seed");

        p.stroke(10);
        p.strokeWeight(0.2);

        p.vertex(0, 0, 0);

        let numPoints = 40; // sweet spot   //RUSS this is the number to change. It's pretty good right now, except it spins faster on larger renderings.
        //                                    Pattern stays the same, at least!
        let innerRadius = 30;
        let outerRadius = p.width * ".4";

        p.beginShape();
        for (let i = 0; i <= numPoints; i++) {
          console.log(
            "mirrorband stripes selectedColors array: ",
            selectedColors
          );
          let theta = p.map(i, 0, numPoints, 0, p.TWO_PI);
          let x = p.cos(theta) * outerRadius;
          let y = p.sin(theta) * outerRadius;
          let fillColor = p.color(selectedColors[currentColorIndex]);
          console.log("mirrorband stripes fill color: ", fillColor);
          fillColor.setAlpha(0.2); // changes pattern - the lower you go the softer the  stripes get (also the more 'super' ish it gets) 0.1 - 0.4
          p.fill(fillColor);
          p.vertex(x, y, 0.2);

          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
          console.log(
            "mirrorband stripes fill currentColorIndex: ",
            currentColorIndex
          );
        }
        p.endShape(p.CLOSE);
      }

      
      function drawBlockyBand(colors) {
        p.randomSeed("seed");
        p.fill(230);
        p.noStroke();

        let numPoints = 100;
        let innerRadius = 30;
        let outerRadius = p.width * ".40";

        p.beginShape(p.TRIANGLE_FAN);
        p.vertex(0, 0, 0);
        for (let i = 0; i <= numPoints; i++) {
          let theta = p.map(i, 0, numPoints, 300, 360);
          let x = p.cos(theta) * outerRadius;
          let y = p.sin(theta) * outerRadius;
          let fillColor = p.color(selectedColors[currentColorIndex]);
          fillColor.setAlpha(0.01);
          p.fill(fillColor);
          p.vertex(x, y, 0.01); // Play with value to get patterns

          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
        }
        p.endShape(p.CLOSE);
      }

      function drawNoiseBand(colors) {
        p.randomSeed("seed");
        p.fill(230);
        p.noStroke(); // turn on others for crackle
        // p.stroke(10)
        // p.strokeWeight(.2)

        let numPoints = 100;
        let innerRadius = 30;
        let outerRadius = p.width * ".40";

        p.beginShape();

        for (let i = 0; i <= numPoints; i++) {
          let n = p.noise(xOff);
          let theta = p.map(i, 0, numPoints, 0, p.TWO_PI);
          let radius =
            (p.sin(p.map(n, 0, 1, -p.PI, p.PI)) + 1) * 0.5 * outerRadius;
          let x = p.cos(theta) * radius;
          let y = p.sin(theta) * outerRadius;
          let fillColor = p.color(selectedColors[currentColorIndex]);
          fillColor.setAlpha(0.1);
          p.fill(fillColor);
          p.vertex(x, y, 0.1);
          xOff += 0.1;

          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
        }
        p.endShape(p.CLOSE);
      }

      function drawSuperBand(colors) {
        p.randomSeed("seed");
        p.stroke(255);
        p.strokeWeight(0.5);

        p.vertex(0, 0, 0);

        let numPoints = 30;
        let outerRadius = p.width * ".40";

        p.beginShape();
        for (let i = 0; i <= numPoints; i++) {
          let theta = p.map(i, 0, numPoints, 0, p.TWO_PI);
          let x = p.cos(theta) * outerRadius;
          let y = p.sin(theta) * outerRadius;
          let fillColor = p.color(selectedColors[i % selectedColors.length]);
          fillColor.setAlpha(0.02); // changes pattern
          let numberOfColors = selectedColors.length;

          p.fill(fillColor);
          p.vertex(x, y, 0.1);

          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
        }
        p.endShape(p.CLOSE);
      }

      ///////////// Bezier Mirroband Combo
      function drawBezierBand() {
        p.randomSeed("seed");
        p.stroke(255);
        p.strokeWeight(0.1);

        let numPoints = 360;
        let innerRadius = 30;
        let outerRadius = p.width * ".40";

        p.beginShape(p.TRIANGLE_FAN);
        p.vertex(0, 0, 0);
        for (let i = 0; i <= numPoints; i++) {
          let theta = p.map(i, 0, numPoints, 200, 360);
          let x = p.cos(theta) * outerRadius;
          let y = p.sin(theta) * outerRadius;

          let fillColor = p.color(selectedColors[currentColorIndex]);
          fillColor.setAlpha(0.15);
          p.fill(fillColor);
          p.vertex(x, y, 0.18);

          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
        }
        p.endShape(p.CLOSE);
      }

      function drawChangingCurves() {
        updateCurvePoints();

        let strokeColor = p.color(230);
        strokeColor.setAlpha(10);
        p.stroke(strokeColor);
        p.strokeWeight(0.6);

        // Set the stroke color outside the loop to apply it to all curves
        for (let i = 0; i < curvePoints.length; i++) {
          let cp = curvePoints[i];
          p.beginShape();
          p.vertex(cp[0], cp[1]);
          p.bezierVertex(cp[2], cp[3], cp[4], cp[5], cp[6], cp[7]);
          p.endShape();
        }
      }

      ///dependency for changingCurves:

      function updateCurvePoints() {
        curvePoints = [];
        let centerX = 0;
        let centerY = 0;
        let numPoints = 260;
        let outerRadius = p.width * ".40";

        for (let i = 0; i < numPoints; i++) {
          let t = p.map(i, 0, numPoints - 1, 0, 1);
          let angle = p.map(i, 0, numPoints - 1, 0, p.TWO_PI);

          // control points for the bezier curve
          //RUSS added the outerRadius and the .003325 scaling factor to fix scalability

          let x1 = centerX + Math.cos(angle) * 80 * outerRadius * 0.003325;
          let y1 = centerY + Math.sin(angle) * 80 * outerRadius * 0.003325;

          let x2 = centerX + Math.cos(angle) * 180 * outerRadius * 0.003325;
          let y2 = centerY + Math.sin(angle) * 330 * outerRadius * 0.003325;

          let x3 = centerX + Math.cos(angle) * 290 * outerRadius * 0.003325;
          let y3 = centerY + Math.sin(angle) * 260 * outerRadius * 0.003325;

          let x4 = centerX + Math.cos(angle) * 290 * outerRadius * 0.003325;
          let y4 = centerY + Math.sin(angle) * 290 * outerRadius * 0.003325;

          curvePoints.push([x1, y1, x2, y2, x3, y3, x4, y4]);
        }

        // Set the stroke settings here
        let strokeColor = p.color(230);
        strokeColor.setAlpha(10);
        p.stroke(strokeColor);
        p.strokeWeight(0.1);
      }

      //////////

      //////////////////////////////////////////////////
      ///////////////////////////////////////////////////// TRAY PATTERNS
      //////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////

      function trayPatternSpin(colors) {
        // p.fill(230);
        p.noStroke();
        p.strokeWeight(1);
        let dyna = p.sin(p.sin(p.frameCount * 0.8) * 60);
        let outerRadius = p.width * 0.05;

        let circumference = 2 * p.PI * outerRadius;
        let numPoints = p.floor(circumference / 4); // Adjust 10 based on the desired spacing

        let centerX = p.width / 3;
        let centerY = p.width / 2;

        p.beginShape();

        for (let i = 0; i <= numPoints; i++) {
          let theta = p.map(i, 0, numPoints, 0, p.TWO_PI);
          let x = centerX + p.cos(theta) * outerRadius;
          let y = centerY + p.sin(theta + p.cos(1)) * outerRadius * 5;
          let fillColor = p.color(selectedColors[currentColorIndex]);
          fillColor.setAlpha(0.2);
          p.fill(fillColor);
          p.vertex(x, y, -0.5);
          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
        }
        p.endShape(p.CLOSE);
      }

      //TRAY PATTERN MARK

      function trayPatternMark(seed) {
        p.randomSeed(seed); // Set the random seed for trayPatternMark
        console.log("tray pattern mark:", seed);
        p.stroke(0.1);
        p.strokeWeight(0.1);

        //4 random colors from the chosenColors array
        const chosenColorIndices = [];
        while (chosenColorIndices.length < 4) {
          const randomIndex = Math.floor(p.random(chosenColors.length));
          if (!chosenColorIndices.includes(randomIndex)) {
            chosenColorIndices.push(randomIndex);
          }
        }
        const arrayName = getArrayName(chosenColors);
        // console.log(
        //   `Chosen color indices from ${arrayName} array: ${chosenColorIndices}`
        // );

        if (tick < 1) {
          for (let i = 1; i < 60; i++) {
            const chosenColorIndex = chosenColorIndices[i % 4];
            const chosenColor = chosenColors[chosenColorIndex];
            moireMark(
              p.width * 0.00005,
              p.width / 2.5,
              i * 100,
              (i * p.sin(0.1)) / 100,
              chosenColor
            );
          }
        }
      }

      function getArrayName(array) {
        const arrayNames = {
          colorsB,
          colorsC,
          colorsD,
          colorsE,
          colorsF,
          colorsG,
          colorsH,
          colorsI,
          colorsJ,
          colorsK,
          colorsL,
          colorsM,
          colorsN,
          colorsO,
          colorsP,
          colors90s,
          colorsAngel,
        };

        // name of the given array
        for (const [name, arr] of Object.entries(arrayNames)) {
          if (arr === array) {
            return name;
          }
        }

        return "Unknown"; // Default to "Unknown" if the array is not found
      }
      function moireMark(x0, y0, length, angle, color) {
        p.push();
        p.translate(x0, y0);

        let x1 = length * p.cos(angle);
        let y1 = length * p.sin(angle);

        p.stroke(color);
        p.line(0, 0, x1, y1);
        p.pop();
      }

      ////////////////////////

      function trayNoiseSweep(seed, colors) {
        p.randomSeed(seed);
        // console.log("static tray mark:", seed);
        p.stroke(0.1);
        p.strokeWeight(p.random(2, 0.1));

        // 4 random colors from the chosenColors array
        const chosenColorIndices = [];
        while (chosenColorIndices.length < 4) {
          const randomIndex = Math.floor(p.random(chosenColors.length));
          if (!chosenColorIndices.includes(randomIndex)) {
            chosenColorIndices.push(randomIndex);
          }
        }
        const arrayName = getArrayName(chosenColors);
        // console.log(
        //   `Chosen color indices from ${arrayName} array: ${chosenColorIndices}`
        // );

        if (tick < 1) {
          for (let i = 1; i < 60; i++) {
            let dyna = p.sin(p.sin(p.frameCount * 0.08) * 60);

            const chosenColorIndex = chosenColorIndices[i % 4];
            const chosenColor = chosenColors[chosenColorIndex];
            sweepMark(
              290 * p.width * 0.00137,
              dyna,
              i * 100,
              (i * p.cos(0.06)) / 100,
              chosenColor
            );
          }
        }
      }

      function sweepMark(x0, y0, length, angle, color) {
        p.push();
        p.translate(x0, y0);

        let x1 = 1400 * p.cos(angle);
        let y1 = 1400 * p.sin(angle);

        p.stroke(color);
        p.line(0, 0, x1, y1);
        p.pop();
      }

      ////////////////
      function trayStaticLove(seed, colors) {
        p.randomSeed(seed); // Set the random seed for trayPatternMark
        // console.log("static tray mark:", seed);
        p.stroke(0.1);
        p.strokeWeight(0.1);
        let outerRadius = 290;
        let randomX = p.random(p.width);
        let randomY = p.random(28, 29);

        // 4 random colors from the chosenColors array
        const chosenColorIndices = [];
        while (chosenColorIndices.length < 4) {
          const randomIndex = Math.floor(p.random(chosenColors.length));
          if (!chosenColorIndices.includes(randomIndex)) {
            chosenColorIndices.push(randomIndex);
          }
        }
        const arrayName = getArrayName(chosenColors);
        // console.log(
        //   `Chosen color indices from ${arrayName} array: ${chosenColorIndices}`
        // );

        if (tick < 1) {
          for (let i = 1; i < 60; i++) {
            let dyna = p.sin(p.sin(p.frameCount * 0.08) * 60);

            const chosenColorIndex = chosenColorIndices[i % 4];
            const chosenColor = chosenColors[chosenColorIndex];
            staticMark(
              290 * p.width * 0.00137,
              randomY,
              i * 100,
              (i * p.cos(0.06)) / 100,
              chosenColor
            );
          }
        }
      }

      function staticMark(x0, y0, length, angle, color) {
        p.push();
        p.translate(x0, y0);

        let x1 = length * p.cos(angle);
        let y1 = length * p.sin(angle);

        p.stroke(color);
        p.line(0, 0, x1, y1);
        p.pop();
      }
      ///////////////

      function trayBlockyGlitchThick(seed, colors) {
        p.randomSeed(seed); // Set the random seed for trayPatternMark
        // console.log("tray mark:", seed);
        p.stroke(0.1);
        p.strokeWeight(5.5);
        let outerRadius = 290;
        let randomX = p.random(p.width);
        let randomY = p.random(27, 29);

        // 4 random colors from the chosenColors array
        const chosenColorIndices = [];
        while (chosenColorIndices.length < 4) {
          const randomIndex = Math.floor(p.random(chosenColors.length));
          if (!chosenColorIndices.includes(randomIndex)) {
            chosenColorIndices.push(randomIndex);
          }
        }
        const arrayName = getArrayName(chosenColors);
        // console.log(
        //   `Chosen color indices from ${arrayName} array: ${chosenColorIndices}`
        // );

        if (tick < 1) {
          for (let i = 1; i < 60; i++) {
            const chosenColorIndex = chosenColorIndices[i % 4];
            const chosenColor = chosenColors[chosenColorIndex];
            blockyMark(
              290 * p.width * 0.00137,
              randomY,
              i * 100,
              (i * p.sin(0.1)) / 100,
              chosenColor
            );
          }
        }
      }

      function blockyMark(x0, y0, length, angle, color) {
        p.push();
        p.translate(x0, y0);

        let x1 = length * p.cos(angle);
        let y1 = length * p.sin(angle);

        p.stroke(color);
        p.line(0, 0, x1, y1);
        p.pop();
      }

      ////////////////////////////////
      ////////////////////////////////////////////
      //INNER HUB
      /////////////////////////////////////////////////
      ////////////////////////////
      function drawInnerHub(seed) {
        p.randomSeed("seed");
        // console.log("innerhub seed:", seed);
        p.fill(230);
        p.stroke(230);
        p.strokeWeight(0.1);

        let numPoints = p.random(70, 200); // Bounds 70 <----> 200
        let innerRadius = p.width * 0.123; // Bounds 45 <----> 100 p.width * .123

        p.beginShape(p.TRIANGLE_FAN);
        p.vertex(0, 0, 0);

        for (let i = 0; i <= numPoints; i++) {
          let theta = p.map(
            i,
            0,
            numPoints,
            200,
            p.TWO_PI
          ); ///// THE FOURTH NUMBER HERE IS THE PATTERN GENERATOR! LETS GO
          //a list of favorites so far: 1000, 20, 380, 60
          let x = p.cos(theta) * innerRadius;
          let y = p.sin(theta) * innerRadius;
          let fillColor = p.color(selectedColors[currentColorIndex]);
          fillColor.setAlpha(0.1);
          p.fill(fillColor);

          p.vertex(x, y, 0.2);

          currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
        }
        p.endShape(p.CLOSE);
      }
      ////////////////////////////////////
      /////////////////////////////////
      //////////////////////////
      //DRAW RINGS

      // function drawRings() {
      //   let strokeColor = p.color(230);
      //   strokeColor.setAlpha(10);
      //   p.stroke(strokeColor);
      //   p.strokeWeight(1);
      //   let ringSize = p.width * 0.0011;
      //   // Draw the ellipses
      //   p.ellipse(0, 0, 160 * ringSize, 160 * ringSize);
      //   p.ellipse(0, 0, 120 * ringSize, 120 * ringSize);
      //   p.ellipse(0, 0, 110 * ringSize, 110 * ringSize);
      //   p.ellipse(0, 0, 50 * ringSize, 50 * ringSize);
      // }

      //DRAW CENTER DOT

      function drawCenterDot() {
        let strokeColor = p.color(10);
        strokeColor.setAlpha(0.5);
        p.stroke(strokeColor);
        p.fill(selections.fillColor);
        p.strokeWeight(3);

        let numPoints = 40;
        let radius = p.width * 0.05;

        p.beginShape();

        for (let i = 0; i < numPoints; i++) {
          let angle = p.map(i, 0, numPoints, 0, p.TWO_PI);
          let x = p.cos(angle) * radius;
          let y = p.sin(angle) * radius;
          p.vertex(x, y, 2);
        }
        p.endShape(p.CLOSE);
      }

      function drawRings() {
        //STILL CAN'T REALLY GET DIFFERENT RING DESIGNS TO WORK
        // set the random seed

        let strokeColor = p.color(230);
        strokeColor.setAlpha(0.5);
        p.stroke(strokeColor);
        p.strokeWeight(p.random(1, 2));

        // let numCircles = p.floor(p.random(2, 6));
        let numCircles = selections.ringCount; //ITS HERE for num circles

        for (let circle = 0; circle < numCircles; circle++) {
          let numPoints = 30;
          let radius = p.random(20, 40) * p.width * 0.0015; //these deal with scale of the rings
          let distance = p.random(20, 40) * p.width * 0.001;

          // outer circle
          p.beginShape();
          p.noFill();
          for (let i = 0; i < numPoints; i++) {
            let angle = p.map(i, 0, numPoints, 0, p.TWO_PI);
            let x = p.cos(angle) * (radius + distance);
            let y = p.sin(angle) * (radius + distance);
            p.vertex(x, y, 1);
          }
          p.endShape(p.CLOSE);
        }
      }
    });

    // Cleanup: Remove the canvas when the component unmounts
    return () => {
      // Cleanup: Remove the first canvas when the component unmounts
      if (p5Instance) {
        p5Instance.remove();
      }

      // Remove the p5 instance
      p5Instance.remove();
    };
  }, [selections.mirrorBand, selections.trayPattern, selections.fillColor]);
  //[songSelection, selections]);

  const handlePlay = () => {
    console.log("handlePlay called");
    setIsPlaying(true);
    audioRef.current.play();
  };

  const handlePause = () => {
    console.log("handlePause called");
    setIsPlaying(false);
    audioRef.current.pause();
  };

  return (
    <div
      style={{ margin: 0, padding: 0, position: "relative" }}
      // onClick={isPlaying ? handlePause : handlePlay}
    >
      <div
        ref={renderRef}
        className="canvas"
        style={{ height: "100%", maxHeight: "100%" }}
      ></div>
      {/* <audio
        ref={audioRef}
        src={`/song${songSelection}.wav`}
        onEnded={handlePause}
        autoPlay
        controls
        hidden
      /> */}
    </div>
  );
};

export default NoBackgroundTest;

//////  Next step is figuring out the clean up component
