import { React, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import DynamicRenderer from "../components/DynamicRenderer";

export default function RendererIPFS() {
  const [fetchedMetadata, setFetchedMetadata] = useState();
  const [selections, setSelections] = useState({}); //defaults  mirrorBand: "blocky",
  //   trayPattern: "blocky",
  //   songId: "1",
  //   fillColor: 255,
  //   titlePatternDesign: "a",
  //   titleColor: "blueberry",
  //   patternColor: "blue",
  //   innerHubValue: 60,
  //   colorPalette: "B",
  //   ringCount: 2,
  //   seedNumber: 200,

  // Function to extract query parameters from the URL
  const getQueryParameter = (name) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(name);
  };

  // Get the 'tokenId' query parameter from the URL
  const tokenId = getQueryParameter("tokenId");

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  function toggleAudio() {
    console.log("audio toggled from canvas click");
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  }

  const handlePlay = () => {
    console.log("handlePlay called");
    setIsPlaying(true);
    audioRef.current.play();
  };

  const handlePause = () => {
    console.log("handlePause called");
    setIsPlaying(false);
    audioRef.current.pause();
  };

  useEffect(() => {
    fetch(`https://c4-generator.vercel.app/api/cdMetadata/${tokenId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFetchedMetadata(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [tokenId]);

  useEffect(() => {
    console.log("fetched metadata object: ", fetchedMetadata);
  }, [fetchedMetadata]);

  //the following useEffect takes the fetched metadata, and reformats it into the object that is needed by the dynamic renderer
  useEffect(() => {
    // Create a copy of the selections object
    const updatedSelections = { ...selections };

    fetchedMetadata?.attributes?.forEach((attribute) => {
      switch (attribute.trait_type) {
        case "Mirror Band":
          updatedSelections.mirrorBand = attribute.value;
          break;
        case "Tray Pattern":
          updatedSelections.trayPattern = attribute.value;
          break;
        case "Song Name":
          updatedSelections.songId = fetchedMetadata.songId;
          break;
        case "Mirror Band Color Palette":
          updatedSelections.mirrorBandSubPalette =
            fetchedMetadata.mirrorBandSubPalette;
          updatedSelections.trayPatternSubPalette =
            fetchedMetadata.trayPatternSubPalette;
          break;
        case "Inner Hub Value":
          updatedSelections.innerHubValue = attribute.value;
          break;
        case "Ring Count":
          updatedSelections.ringCount = attribute.value;
          break;
        case "Title Design":
          updatedSelections.titleDesign = attribute.value;
          break;
        case "Pattern Design":
          updatedSelections.patternDesign = attribute.value;
          break;
        case "Title Color":
          updatedSelections.titleColor = attribute.value;
          break;
        case "Pattern Color":
          updatedSelections.patternColor = attribute.value;
          break;
        case "Seed Number":
          updatedSelections.seedNumber = attribute.value;
          break;
        default:
          // Handle other attributes as needed
          break;
      }
    });

    // Update the selections with the modified object
    setSelections(updatedSelections);
  }, [fetchedMetadata]);

  useEffect(() => {
    console.log("formatted selections: ", selections);
  }, [selections]);

  const cspPolicy = "default-src 'self' https://c4-generator.vercel.app;";

  return (
    <>
      <head>
        <meta http-equiv="Content-Security-Policy" content={cspPolicy} />
        {/* Add other head elements here */}
      </head>
      <div onClick={toggleAudio}>
        <audio
          ref={audioRef}
          src={`/songs/${selections.songId}.wav`}
          onEnded={handlePause}
          controls
          hidden
        />
        <DynamicRenderer selections={selections} />
      </div>
    </>
  );
}

//okay, now I need to determine the selected colors in the master component, but for that I should also pass the seed number value in the selections object
