import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import DynamicRenderer from "./components/DynamicRenderer";

import OpenseaAllPatternsTest from "./components/OpenseaAllPatternsTest";
import TitlePattern from "./components/TitlePattern";
import Arttest from "./pages/Arttest";
import Home from "./pages/Home";
import PaletteVisualizer from "./pages/PaletteVisualizer";
import Renderer from "./pages/Renderer";
import Blank1 from "./pages/Blank1";
import Blank2 from "./pages/Blank2";
import RendererNoBg from "./pages/RendererNoBg";
import RendererIPFS from "./pages/RendererIPFS";

const mirrorBands = ["noise", "stripes", "super", "blocky", "bezier"]; // holo and bezier removed temporarily
const trayPatterns = ["mark", "spin", "noise", "static", "blocky"];

function App() {
  const randomMirrorBandIndex = Math.floor(Math.random() * mirrorBands.length);
  const randomTrayPatternIndex = Math.floor(
    Math.random() * trayPatterns.length
  );

  const selections = {
    mirrorBand: mirrorBands[randomMirrorBandIndex],
    trayPattern: trayPatterns[randomTrayPatternIndex],
  };

  const song = Math.floor(Math.random() * (5 - 1) + 1);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={RendererIPFS}></Route>
        <Route path="/arttest" Component={Arttest} />
        <Route path="/palettes" Component={PaletteVisualizer} />
        <Route path="/blank1" Component={Blank1} />
        <Route path="/blank2" Component={Blank2} />
        <Route path="/renderer/:tokenId" Component={Renderer} />
        <Route path="/renderernobg/:tokenId" Component={RendererNoBg} />
      </Routes>
    </BrowserRouter>
  );
}

//NEXT STEP IS FIGURING OUT A FIX FOR CONSISTEN TITLEPATTERN SPINNING

export default App;
