import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const cspPolicy = "default-src 'self' https://c4-generator.vercel.app https://c4testbucket.s3.us-east-2.amazonaws.com;";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <head>
      <meta httpEquiv="Content-Security-Policy" content={cspPolicy} />
      {/* Add other head elements here */}
    </head>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();