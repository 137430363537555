import React, { useEffect, useMemo, useRef, useState } from 'react'
import p5 from 'p5'

import { P5PatternManager } from './utils'

const OpenseaAllPatternsTest = ({ selections }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)

  //console log the above properties
  useEffect(() => {
    console.log('selections changed in the openseapatterns component')
  }, [selections])

  console.log('selections::', selections)

  const renderRef = useRef()

  let p5Instance = useMemo(() => {
    let angle = 0
    let rotationSpeed = 1.0 // Adjust speed
    return new p5((p) => {
      //SET UP FUNCTION
      const patternManager = new P5PatternManager({
        p,
        selections,
      })
      patternManager.setUp()
      patternManager.handleResizeEvents()

      patternManager.p.draw = () => {
        angle += rotationSpeed
        if (angle >= 360) {
          angle = 0
        }

        p.rotateY(p.radians(0.01)) // Play with value to get patterns
        p.rotateX(p.radians(0.01)) // Play with value to get patterns
        p.rotateZ(p.radians(angle))

        switch (selections.mirrorBand) {
          case 'Holoprint':
            // Call the drawBezierBand function
            patternManager.drawBezierBand()
            patternManager.drawChangingCurves()

            break
          case 'Smoke':
            // Call the drawNoiseBand function
            patternManager.drawNoiseBand()
            break
          case 'Rolled Candy':
            // Call the drawStripesBand function
            patternManager.drawStripesBand()
            break
          case 'Haze':
            // Call the drawSuperBand function
            patternManager.drawSuperBand()
            break
          case 'Pixelated':
            // Call the drawBlockyBand function
            patternManager.drawBlockyBand()
            break
          default:
            // Handle the default case if needed
            break
        }

        switch (selections.trayPattern) {
          case 'Irridescent':
            // Call the trayPatternMark function
            patternManager.trayPatternMark()
            break
          case 'Spirofoil':
            // Call the trayPatternSpin function
            patternManager.trayPatternSpin()
            break
          case 'Strobe':
            // Call the trayNoiseSweep function
            patternManager.trayNoiseSweep()
            break
          case 'Lenticular':
            // Call the trayStaticLove function
            patternManager.trayStaticLove()
            break
          case 'Sundial':
            // Call the trayBlockyGlitchThick function
            patternManager.trayBlockyGlitchThick()
            break
          default:
            // Handle the default case if needed
            break
        }

        patternManager.drawInnerHub() // alternate patterns come from changing the component.  we could make pass an argument for it, which is used for this variable, and use switch case like the others
        patternManager.drawCenterDot()
        patternManager.drawRings()
      }
    })
  }, [selections])

  ///TRYING TO CAPTURE AN IMAGE

  ////////////////////////////////////////////////////////

  ///MIRORR BANDS////////////////////////

  useEffect(() => {
    // Cleanup: Remove the canvas when the component unmounts
    return () => {
      p5Instance?.remove()
    }
  }, [p5Instance, selections.mirrorBand, selections.trayPattern])
  //[songSelection, selections]);

  function toggleAudio() {
    console.log('audio toggled from canvas click')
    if (isPlaying) {
      setIsPlaying(false)
      audioRef.current.pause()
    } else {
      setIsPlaying(true)
      audioRef.current.play()
    }
  }

  return (
    <div
      style={{ margin: 0, padding: 0, position: 'relative' }}
      onClick={toggleAudio}
    >
      <div
        ref={renderRef}
        className="canvas"
        style={{ height: '100%', maxHeight: '100%' }}
      ></div>
      {/* <audio
        ref={audioRef}
        src={`/songs/${selections.songId}.wav`}
        onEnded={handlePause}
        autoPlay
        controls
        hidden
      /> */}
    </div>
  )
}

export default OpenseaAllPatternsTest

//////  Next step is figuring out the clean up component
