import {
  colorsB,
  colorsC,
  colorsD,
  colorsE,
  colorsF,
  colorsG,
  colorsH,
  colorsI,
  colorsJ,
  colorsK,
  colorsL,
  colorsM,
  colorsN,
  colorsO,
  colorsP,
  colors90s,
  colorsAngel,
} from './constants'

export function getArrayName(array) {
  const arrayNames = {
    colorsB,
    colorsC,
    colorsD,
    colorsE,
    colorsF,
    colorsG,
    colorsH,
    colorsI,
    colorsJ,
    colorsK,
    colorsL,
    colorsM,
    colorsN,
    colorsO,
    colorsP,
    colors90s,
    colorsAngel,
  }

  // name of the given array
  for (const [name, arr] of Object.entries(arrayNames)) {
    if (arr === array) {
      return name
    }
  }

  return 'Unknown' // Default to "Unknown" if the array is not found
}

// These are the same
export function chooseFromFullArray(arrays) {
  return arrays[Math.floor(Math.random() * arrays.length)]
}

export function chooseFromSelectArray(arrays) {
  return arrays[Math.floor(Math.random() * arrays.length)]
}

let xOff = 0
let curvePoints = []
let tick = 0.1

export class P5PatternManager {
  constructor({ p, selections }) {
    this.p = p
    this.selections = selections
    this.selectedColors = selections.mirrorBandSubPalette
    this.chosenColors = selections.trayPatternSubPalette
    this.currentColorIndex = 0
  }

  setUp() {
    const p = this.p
    p.setup = () => {
      p.createCanvas(
        p.min(p.windowHeight, p.windowWidth),
        p.min(p.windowHeight, p.windowWidth),
        p.WEBGL
      )
      
      p.colorMode(p.HSB)
      p.background(255)
      p.noStroke()
      p.smooth()
      p.blendMode(p.BLEND)
    }
  }

  handleResizeEvents() {
    const p = this.p
    p.windowResized = () => {
      // Recalculate canvas size based on the smaller dimension
      const canvasSize = p.min(p.windowWidth, p.windowHeight)
      p.resizeCanvas(canvasSize, canvasSize)
      p.background(255)
    }
  }

  drawCenterDot() {
    const p = this.p
    let strokeColor = p.color(255)
    strokeColor.setAlpha(0.5)
    p.stroke(strokeColor)
    p.fill(255)
    p.strokeWeight(3)

    let numPoints = 40
    let radius = p.width * 0.05

    p.beginShape()

    for (let i = 0; i < numPoints; i++) {
      let angle = p.map(i, 0, numPoints, 0, p.TWO_PI)
      let x = p.cos(angle) * radius
      let y = p.sin(angle) * radius
      p.vertex(x, y, 2)
    }
    p.endShape(p.CLOSE)
  }

  drawInnerHub() {
    const { p, selectedColors, selections } = this
    let currentColorIndex = this.currentColorIndex
    p.randomSeed('seed')
    // console.log("innerhub seed:", seed);
    p.fill(230)
    p.stroke(230)
    p.strokeWeight(0.1)

    let numPoints = p.random(70, 200) // Bounds 70 <----> 200
    let innerRadius = p.width * 0.123 // Bounds 45 <----> 100 p.width * .123

    p.beginShape(p.TRIANGLE_FAN)
    p.vertex(0, 0, 0)

    for (let i = 0; i <= numPoints; i++) {
      let theta = p.map(i, 0, numPoints, selections.innerHub, p.TWO_PI) ///// THE FOURTH NUMBER HERE IS THE PATTERN GENERATOR! LETS GO
      //a list of favorites so far: 1000, 20, 380, 60
      let x = p.cos(theta) * innerRadius
      let y = p.sin(theta) * innerRadius
      let fillColor = p.color(selectedColors[currentColorIndex])
      fillColor.setAlpha(0.1)
      p.fill(fillColor)

      p.vertex(x, y, 0.2)

      currentColorIndex = (currentColorIndex + 1) % selectedColors.length
    }
    p.endShape(p.CLOSE)
  }

  drawRings() {
    //STILL CAN'T REALLY GET DIFFERENT RING DESIGNS TO WORK
    // set the random seed
    const { p, selections } = this

    let strokeColor = p.color(230)
    strokeColor.setAlpha(0.5)
    p.stroke(strokeColor)
    p.strokeWeight(p.random(1, 2))

    // let numCircles = p.floor(p.random(2, 6));
    let numCircles = selections.ringCount //ITS HERE for num circles

    for (let circle = 0; circle < numCircles; circle++) {
      let numPoints = 30
      let radius = p.random(20, 40) * p.width * 0.0015 //these deal with scale of the rings
      let distance = p.random(20, 40) * p.width * 0.001

      // outer circle
      p.beginShape()
      p.noFill()
      for (let i = 0; i < numPoints; i++) {
        let angle = p.map(i, 0, numPoints, 0, p.TWO_PI)
        let x = p.cos(angle) * (radius + distance)
        let y = p.sin(angle) * (radius + distance)
        p.vertex(x, y, 1)
      }
      p.endShape(p.CLOSE)
    }
  }

  drawStripesBand() {
    const { p, selectedColors } = this
    let currentColorIndex = this.currentColorIndex
    p.stroke(10)
    p.strokeWeight(0.2)

    p.vertex(0, 0, 0)

    let numPoints = 80 // sweet spot   //RUSS this is the number to change. It's pretty good right now, except it spins faster on larger renderings.
    //                                    Pattern stays the same, at least!   NOTE: as you increase or decrease the selectedColors subpalette length, you need to change this,
    // let innerRadius = 30
    let outerRadius = p.width * '.4'

    p.beginShape()
    for (let i = 0; i <= numPoints; i++) {
      let theta = p.map(i, 0, numPoints, 0, p.TWO_PI)
      let x = p.cos(theta) * outerRadius
      let y = p.sin(theta) * outerRadius
      let fillColor = p.color(selectedColors[currentColorIndex])
      fillColor.setAlpha(0.2) // changes pattern - the lower you go the softer the  stripes get (also the more 'super' ish it gets) 0.1 - 0.4
      p.fill(fillColor)
      p.vertex(x, y, 0.2)

      currentColorIndex = (currentColorIndex + 1) % selectedColors.length
    }
    p.endShape(p.CLOSE)
  }

  drawBlockyBand() {
    const { p, selectedColors } = this
    let currentColorIndex = this.currentColorIndex
    p.randomSeed('seed')
    p.fill(230)
    p.noStroke()

    let numPoints = 100
    // let innerRadius = 30
    let outerRadius = p.width * '.40'

    p.beginShape(p.TRIANGLE_FAN)
    p.vertex(0, 0, 0)
    for (let i = 0; i <= numPoints; i++) {
      let theta = p.map(i, 0, numPoints, 300, 360)
      let x = p.cos(theta) * outerRadius
      let y = p.sin(theta) * outerRadius
      if (!selectedColors) return
      let fillColor = p.color(selectedColors[currentColorIndex])
      fillColor.setAlpha(0.01)
      p.fill(fillColor)
      p.vertex(x, y, 0.01) // Play with value to get patterns

      // currentColorIndex = (currentColorIndex + 1) % selectedColors.length;
      currentColorIndex = (currentColorIndex + 1) % selectedColors.length
    }
    p.endShape(p.CLOSE)
  }

  drawNoiseBand() {
    const { p, selectedColors } = this
    let currentColorIndex = this.currentColorIndex
    p.randomSeed('seed')
    p.fill(230)
    p.noStroke() // turn on others for crackle
    // p.stroke(10)
    // p.strokeWeight(.2)

    let numPoints = 100
    // let innerRadius = 30
    let outerRadius = p.width * '.40'

    p.beginShape()

    for (let i = 0; i <= numPoints; i++) {
      let n = p.noise(xOff)
      let theta = p.map(i, 0, numPoints, 0, p.TWO_PI)
      let radius = (p.sin(p.map(n, 0, 1, -p.PI, p.PI)) + 1) * 0.5 * outerRadius
      let x = p.cos(theta) * radius
      let y = p.sin(theta) * outerRadius
      let fillColor = undefined
      if (selectedColors && selectedColors?.[currentColorIndex]) {
        fillColor = p.color(selectedColors[currentColorIndex])
      }
      fillColor?.setAlpha(0.1)
      fillColor && p.fill(fillColor)
      p.vertex(x, y, 0.1)
      xOff += 0.1

      currentColorIndex = (currentColorIndex + 1) % selectedColors?.length
    }
    p.endShape(p.CLOSE)
  }

  drawSuperBand(colors) {
    const { p, selectedColors } = this
    let currentColorIndex = this.currentColorIndex
    p.randomSeed('seed')
    p.stroke(255)
    p.strokeWeight(0.5)

    p.vertex(0, 0, 0)

    let numPoints = 30
    let outerRadius = p.width * '.40'

    p.beginShape()
    for (let i = 0; i <= numPoints; i++) {
      let theta = p.map(i, 0, numPoints, 0, p.TWO_PI)
      let x = p.cos(theta) * outerRadius
      let y = p.sin(theta) * outerRadius
      let fillColor = p.color(selectedColors[i % selectedColors.length])
      fillColor.setAlpha(0.02) // changes pattern
      // let numberOfColors = selectedColors.length
      p.fill(fillColor)
      p.vertex(x, y, 0.1)

      currentColorIndex = (currentColorIndex + 1) % selectedColors.length
    }
    p.endShape(p.CLOSE)
  }

  ///////////// Bezier Mirroband Combo
  drawBezierBand() {
    const { p, selectedColors } = this
    let currentColorIndex = this.currentColorIndex
    p.randomSeed('seed')
    p.stroke(255)
    p.strokeWeight(0.1)

    let numPoints = 360
    // let innerRadius = 30
    let outerRadius = p.width * '.40'

    p.beginShape(p.TRIANGLE_FAN)
    p.vertex(0, 0, 0)
    for (let i = 0; i <= numPoints; i++) {
      let theta = p.map(i, 0, numPoints, 200, 360)
      let x = p.cos(theta) * outerRadius
      let y = p.sin(theta) * outerRadius

      let fillColor = p.color(selectedColors[currentColorIndex])
      fillColor.setAlpha(0.15)
      p.fill(fillColor)
      p.vertex(x, y, 0.18)

      currentColorIndex = (currentColorIndex + 1) % selectedColors.length
    }
    p.endShape(p.CLOSE)
  }

  drawChangingCurves() {
    this.updateCurvePoints()
    const { p } = this

    let strokeColor = p.color(230)
    strokeColor.setAlpha(10)
    p.stroke(strokeColor)
    p.strokeWeight(0.6)

    // Set the stroke color outside the loop to apply it to all curves
    for (let i = 0; i < curvePoints.length; i++) {
      let cp = curvePoints[i]
      p.beginShape()
      p.vertex(cp[0], cp[1])
      p.bezierVertex(cp[2], cp[3], cp[4], cp[5], cp[6], cp[7])
      p.endShape()
    }
  }

  ///dependency for changingCurves:

  updateCurvePoints() {
    const { p } = this
    curvePoints = []
    let centerX = 0
    let centerY = 0
    let numPoints = 260
    let outerRadius = p.width * '.40'

    for (let i = 0; i < numPoints; i++) {
      // let t = p.map(i, 0, numPoints - 1, 0, 1)
      let angle = p.map(i, 0, numPoints - 1, 0, p.TWO_PI)

      // control points for the bezier curve
      //RUSS added the outerRadius and the .003325 scaling factor to fix scalability

      let x1 = centerX + Math.cos(angle) * 80 * outerRadius * 0.003325
      let y1 = centerY + Math.sin(angle) * 80 * outerRadius * 0.003325

      let x2 = centerX + Math.cos(angle) * 180 * outerRadius * 0.003325
      let y2 = centerY + Math.sin(angle) * 330 * outerRadius * 0.003325

      let x3 = centerX + Math.cos(angle) * 290 * outerRadius * 0.003325
      let y3 = centerY + Math.sin(angle) * 260 * outerRadius * 0.003325

      let x4 = centerX + Math.cos(angle) * 290 * outerRadius * 0.003325
      let y4 = centerY + Math.sin(angle) * 290 * outerRadius * 0.003325

      curvePoints.push([x1, y1, x2, y2, x3, y3, x4, y4])
    }

    // Set the stroke settings here
    let strokeColor = p.color(230)
    strokeColor.setAlpha(10)
    p.stroke(strokeColor)
    p.strokeWeight(0.1)
  }

  //////////

  //////////////////////////////////////////////////
  ///////////////////////////////////////////////////// TRAY PATTERNS
  //////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////

  trayPatternSpin() {
    const { chosenColors, p } = this
    let currentColorIndex = this.currentColorIndex
    // p.fill(230);
    p.noStroke()
    p.strokeWeight(1)
    // let dyna = p.sin(p.sin(p.frameCount * 0.8) * 60)
    let outerRadius = p.width * 0.05

    let circumference = 2 * p.PI * outerRadius
    let numPoints = p.floor(circumference / 4) // Adjust 10 based on the desired spacing

    let centerX = p.width / 3
    let centerY = p.width / 2

    p.beginShape()

    for (let i = 0; i <= numPoints; i++) {
      let theta = p.map(i, 0, numPoints, 0, p.TWO_PI)
      let x = centerX + p.cos(theta) * outerRadius
      let y = centerY + p.sin(theta + p.cos(1)) * outerRadius * 5
      
      let fillColor = p.color(chosenColors?.[currentColorIndex])
      if (!fillColor) return
      fillColor.setAlpha(0.2)
      p.fill(fillColor)
      p.vertex(x, y, -0.5)
      currentColorIndex = (currentColorIndex + 1) % chosenColors.length
    }
    p.endShape(p.CLOSE)
  }

  //TRAY PATTERN MARK

  //this function uses 'chosen colors'

  trayPatternMark(seed) {
    const { chosenColors, p } = this
    p.randomSeed(seed) // Set the random seed for trayPatternMark
    p.stroke(0.1)
    p.strokeWeight(0.1)

    //4 random colors from the chosenColors array
    const chosenColorIndices = []
    while (chosenColorIndices.length < 7) {
      const randomIndex = Math.floor(p.random(chosenColors?.length))
      if (!chosenColorIndices.includes(randomIndex)) {
        chosenColorIndices.push(randomIndex)
      }
    }
    // const arrayName = getArrayName(chosenColors);
    // console.log(
    //   `Chosen color indices from ${arrayName} array: ${chosenColorIndices}`
    // );

    if (tick < 1) {
      for (let i = 1; i < 60; i++) { //60
        const chosenColorIndex = chosenColorIndices[i % 7]
        const chosenColor = chosenColors[chosenColorIndex]
        // console.log(selectedColors[chosenColorIndex])
        this.moireMark(
          p.width * 0.00005,
          p.width / 2.5,
          i * 100,
          (i * p.sin(0.1)) / 100 ,
          chosenColor
        )
      }
    }
  }

  moireMark(x0, y0, length, angle, color) {
    const { p } = this
    p.push()
    p.translate(x0, y0)

    let x1 = length * p.cos(angle)
    let y1 = length * p.sin(angle)

    p.stroke(color)
    p.line(0, 0, x1, y1)
    p.pop()
  }

  ////////////////////////

  trayNoiseSweep(seed) {
    const { chosenColors, p } = this
    p.randomSeed(seed)
    // console.log("static tray mark:", seed);
    p.stroke(0.1)
    p.strokeWeight(p.random(2, 0.1))

    // 4 random colors from the chosenColors array
    const chosenColorIndices = []
    while (chosenColorIndices.length < 4) {
      const randomIndex = Math.floor(p.random(chosenColors.length))
      if (!chosenColorIndices.includes(randomIndex)) {
        chosenColorIndices.push(randomIndex)
      }
    }

    if (tick < 1) {
      for (let i = 1; i < 60; i++) {
        let dyna = p.sin(p.sin(p.frameCount * 0.08) * 60)

        const chosenColorIndex = chosenColorIndices[i % 4]
        const chosenColor = chosenColors[chosenColorIndex]
        this.sweepMark(
          290 * p.width * 0.00137,
          dyna,
          i * 100,
          (i * p.cos(0.06)) / 100,
          chosenColor
        )
      }
    }
  }

  sweepMark(x0, y0, length, angle, color) {
    const { p } = this
    p.push()
    p.translate(x0, y0)

    let x1 = 1400 * p.cos(angle)
    let y1 = 1400 * p.sin(angle)

    p.stroke(color)
    p.line(0, 0, x1, y1)
    p.pop()
  }

  ////////////////
  trayStaticLove(seed, colors) {
    const { chosenColors, p } = this
    p.randomSeed(seed) // Set the random seed for trayPatternMark
    // console.log("static tray mark:", seed);
    p.stroke(0.1)
    p.strokeWeight(0.1)
    // let outerRadius = 290;
    // let randomX = p.random(p.width);
    let randomY = p.random(28, 29)

    // 4 random colors from the chosenColors array
    const chosenColorIndices = []
    while (chosenColorIndices.length < 4) {
      const randomIndex = Math.floor(p.random(chosenColors.length))
      if (!chosenColorIndices.includes(randomIndex)) {
        chosenColorIndices.push(randomIndex)
      }
    }
    // const arrayName = getArrayName(chosenColors);
    // console.log(
    //   `Chosen color indices from ${arrayName} array: ${chosenColorIndices}`
    // );

    if (tick < 1) {
      for (let i = 1; i < 60; i++) {
        // let dyna = p.sin(p.sin(p.frameCount * 0.08) * 60)

        const chosenColorIndex = chosenColorIndices[i % 4]
        const chosenColor = chosenColors[chosenColorIndex]
        this.staticMark(
          290 * p.width * 0.00137,
          randomY,
          i * 100,
          (i * p.cos(0.06)) / 100,
          chosenColor
        )
      }
    }
  }

  staticMark(x0, y0, length, angle, color) {
    const { p } = this
    p.push()
    p.translate(x0, y0)

    let x1 = length * p.cos(angle)
    let y1 = length * p.sin(angle)

    p.stroke(color)
    p.line(0, 0, x1, y1)
    p.pop()
  }
  ///////////////

  trayBlockyGlitchThick(seed) {
    const { chosenColors, p } = this
    p.randomSeed(seed) // Set the random seed for trayPatternMark
    // console.log("tray mark:", seed);
    p.stroke(0.1)
    p.strokeWeight(5.5)
    // let outerRadius = 290;
    // let randomX = p.random(p.width);
    let randomY = p.random(27, 29)

    // 4 random colors from the chosenColors array
    const chosenColorIndices = []
    while (chosenColorIndices.length < 4) {
      const randomIndex = Math.floor(p.random(chosenColors?.length))
      if (!chosenColorIndices.includes(randomIndex)) {
        chosenColorIndices.push(randomIndex)
      }
    }
    // const arrayName = getArrayName(chosenColors);
    // console.log(
    //   `Chosen color indices from ${arrayName} array: ${chosenColorIndices}`
    // );

    if (tick < 1) {
      for (let i = 1; i < 60; i++) {
        const chosenColorIndex = chosenColorIndices[i % 4]
        const chosenColor = chosenColors[chosenColorIndex]
        this.blockyMark(
          290 * p.width * 0.00137,
          randomY,
          i * 100,
          (i * p.sin(0.1)) / 100,
          chosenColor
        )
      }
    }
  }

  blockyMark(x0, y0, length, angle, color) {
    const { p } = this
    p.push()
    p.translate(x0, y0)

    let x1 = length * p.cos(angle)
    let y1 = length * p.sin(angle)

    p.stroke(color)
    p.line(0, 0, x1, y1)
    p.pop()
  }
}
