import React from 'react'
import './PaletteVisualizer.css'

const colorsB = ["#202311", "#7574AA", "#C5C6C0", "#F7AE04", "#7F5127"];

  const colorsC = ["#582E29", "#694234", "#EBC908", "#4C4679", "#D96249"];

  const colorsD = [
    "#10130E",
    "#249E92",
    "#9E5847",
    "#4C5543",
    "#ACDBD1",
    "#7078B5",
  ];

  const colorsE = [
    "#545A19",
    "#249E92",
    "#ABA4A5",
    "#A97AAA",
    "#787969",
    "#C8CA05",
    "#4C4679",
  ];

  const colorsF = [
    "#D9B883",
    "#D59688",
    "#EBC908",
    "#70756A",
    "#A1708C",
    "#F2C729",
  ];

  const colorsG = [
    "#80D15F",
    "#EBC908",
    "#5D889C",
    "#B6B728",
    "#E1CB84",
    "#CFD418",
  ];

  const colorsH = ["#F4613A", "#E54864", "#7078B5", "#4866AC", "#75617B"];

  const colorsI = [
    "#582E29",
    "#694234",
    "#202311",
    "#7574AA",
    "#C5C6C0",
    "#F7AE04",
    "#7F5127",
  ];

  const colorsJ = [
    "#EBC908",
    "#4C4679",
    "#D59688",
    "#70756A",
    "#A1708C",
    "#D96249",
  ];

  const colorsK = [
    "#10130E",
    "#249E92",
    "#D9B883",
    "#D59688",
    "#9E5847",
    "#F2C729",
    "#ACDBD1",
  ];

  const colorsL = ["#E54864", "#7078B5", "#A97AAA", "#787969", "#C8CA05"];

  const colorsM = [
    "#D9B883",
    "#D59688",
    "#70756A",
    "#80D15F",
    "#5D889C",
    "#B6B728",
    "#E54864",
  ];

  const colorsN = [
    "#80D15F",
    "#EBC908",
    "#582E29",
    "#694234",
    "#E1CB84",
    "#CFD418",
  ];

  const colorsO = ["#F4613A", "#E54864", "#7078B5", "#4866AC", "#75617B"];

  const colorsP = ["#F4613A", "#E54864", "#EBC908", "#4C4679", "#D59688"];

  const colorsSuper = [
    "#B69674",
    "#61553F",
    "#B5756A",
    "#4C4C42",
    "#4F608C",
    "#202311",
    "#7574AA",
    "#C5C6C0",
    "#F7AE04",
    "#7F5127",
    "#582E29",
    "#694234",
    "#EBC908",
    "#4C4679",
    "#D96249",
    "#10130E",
    "#249E92",
    "#9E5847",
    "#4C5543",
    "#ACDBD1",
    "#545A19",
    "#ABA4A5",
    "#A97AAA",
    "#787969",
    "#C8CA05",
    "#D9B883",
    "#D59688",
    "#70756A",
    "#A1708C",
    "#F2C729",
    "#80D15F",
    "#5D889C",
    "#B6B728",
    "#E1CB84",
    "#CFD418",
    "#F4613A",
    "#E54864",
    "#7078B5",
    "#4866AC",
    "#75617B",
  ];

  //colors for mirrorband & rest
  const colors90s = [
    "#B69674",
    "#61553F",
    "#B5756A",
    "#4C4C42",
    "#4F608C",
    "#202311",
    "#7574AA",
    "#C5C6C0",
    "#F7AE04",
    "#7F5127",
    "#582E29",
    "#694234",
    "#EBC908",
    "#4C4679",
    "#D96249",
    "#10130E",
    "#249E92",
    "#9E5847",
    "#4C5543",
    "#ACDBD1",
    "#545A19",
    "#ABA4A5",
    "#A97AAA",
    "#787969",
    "#C8CA05",
    "#D9B883",
    "#D59688",
    "#70756A",
    "#A1708C",
    "#F2C729",
    "#80D15F",
    "#5D889C",
    "#B6B728",
    "#E1CB84",
    "#CFD418",
    "#F4613A",
    "#E54864",
    "#7078B5",
    "#4866AC",
    "#75617B",
  ];

  const colorsAngel = [
    "#5FA4D5",
    "#2E4B45",
    "#1453A8",
    "#D5BBC4",
    "#A5C3D7",
    "#392EA1",
    "#014920",
    "#584D92",
    "#1E885E",
    "#B3F5D1",
    "#AEDCF2",
    "#0F435E",
    "#9E674B",
    "#A66431",
    "#8BF9A0",
    "#FDC499",
    "#5A5FB6",
    "#55001F",
    "#1E885E",
    "#363F82",
    "#6D7494",
    "#60ABB9",
    "#9F4945",
    "#8C246A",
    "#172E43",
    "#FDC499",
    "#437BDF",
    "#55001F",
    "#1E885E",
    "#134F3F",
  ];

  const ColorPalette = ({ paletteName, colors }) => (
    <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '20px' }}>
      <h2>{paletteName}</h2>
      <div className="color-palette">
        {colors.map((color, index) => (
          <div key={index} className="color-swatch">
            <div
              className="color-box"
              style={{ backgroundColor: color }}
            ></div>
            <div className="color-text">{color}</div>
          </div>
        ))}
      </div>
    </div>
  );

export default function PaletteVisualizer() {
    const colorPalettes = [
        { name: 'B', colors: colorsB },
        { name: 'C', colors: colorsC },
        { name: 'D', colors: colorsD },
        { name: 'E', colors: colorsE },
        { name: 'F', colors: colorsF },
        { name: 'G', colors: colorsG },
        { name: 'H', colors: colorsH },
        { name: 'I', colors: colorsI },
        { name: 'J', colors: colorsJ },
        { name: 'K', colors: colorsK },
        { name: 'L', colors: colorsL },
        { name: 'M', colors: colorsM },
        { name: 'N', colors: colorsN },
        { name: 'O', colors: colorsO },
        { name: 'P', colors: colorsP },
        { name: 'Super', colors: colorsSuper },
        { name: '90s', colors: colors90s },
        { name: 'Angel', colors: colorsAngel },
      ];
      return (
        <div>
            <h1>Color Palettes</h1>
        <div style={{height: "100vh", margin:"3%", display:"flex"}}>
          
          {colorPalettes.map((palette, index) => (
            <ColorPalette
              key={index}
              paletteName={palette.name}
              colors={palette.colors}
            />
          ))}
        </div>
        </div>
      );
}
