import React from 'react'
import NoBackgroundTest from '../components/NoBackgroundTest'
import TitlePatternNoSpin from '../components/TitlePatternNoSpin'

const selections = {
    mirrorBand: "blank1",
    trayPattern: "blocky",
    songId: "1",
    fillColor: 255,
    // titlePatternDesign: "a", // Default value
    titleColor: "blueberry", // Default value
    patternColor: "blue", // Default value
    innerHubValue: 60,
    colorPalette: "B",
    ringCount: 2,
  }

export default function Blank1() {
  return (
    <div>
      <TitlePatternNoSpin selections={selections}/>
      <NoBackgroundTest selections={selections}/>
    </div>
  )
}
